import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpModule } from '@angular/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DatePicker } from '@capacitor-community/date-picker';
import { CommonModule } from '@angular/common';

import { IdeateProvider, IdeateValidators } from './providers/ideateProvider';

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		HttpModule,
		FormsModule,
		ReactiveFormsModule,
		IonicStorageModule.forRoot(),
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HammerModule,
		CommonModule,
		// RecaptchaV3Module
	],
	providers: [
		IdeateProvider,
		IdeateValidators,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
