/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { IdeateProvider } from './providers/ideateProvider';
@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

	constructor(
		public ideateProvider: IdeateProvider,
	) {}

	async ngOnInit() {
		// remove dark mode
		const prefersDark = window.matchMedia('(prefers-color-scheme: light)');
		document.body.classList.remove('dark');
		/* const prefersLight = window.matchMedia('(prefers-color-scheme: light)');
		this.ideateProvider.isDarkMode = prefersLight.matches; */
		console.log('App --> ngOnInit');
	}
}
