import { NgModule, Component } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: 'how-it-works',
		loadChildren: () =>
			import('./pages/how-it-works/how-it-works.module').then(m => m.HowItWorksPageModule)
	},
	{
		path: '',
		loadChildren: () => import('./pages/homepage/homepage.module').then(m => m.HomepagePageModule)
	},
	{
		path: 'home',
		redirectTo: ''
	},
	{
		path: 'about-us',
		loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsPageModule)
	},
	{
		path: 'terms-and-condition',
		loadChildren: () => import('./pages/terms-and-condition/terms-and-condition.module').then(m => m.TermsAndConditionPageModule)
	},
	{
		path: 'is-kosherdat-for-you',
		loadChildren: () => import('./pages/is-kosherdat-for-you/is-kosherdat-for-you.module').then( m => m.IsKosherdatForYouPageModule)
	},
	{
		path: 'contact-us',
		loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
