/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	SITE_NAME: 'KosherDat',
	production: false,
	AppVersion: '0.0.1',
	/* WEBurl: 'https://crazyshidduchim.webarysites.com/',
	APIurl: 'http://localhost/crazy_shidduchim/api/',
	WEBurl: 'https://crazyshidduchim.webarysites.com/',
	APIurl: 'https://system.crazyshidduchim.webarysites.com/api/',
	WebAppUrl: 'https://app.kosherdat.com/', */

	// KosherDat Beta Portal
	WEBurl: 'https://beta.kosherdat.com/',
	APIurl: 'https://beta.system.kosherdat.com/api/',
	WebAppUrl: 'https://beta.app.kosherdat.com/',

	// KosherDat Live Portal
	/*
		WEBurl: 'https://kosherdat.com/',
		APIurl: 'https://system.kosherdat.com/api/',
		WebAppUrl: 'https://app.kosherdat.com/',
	*/

	APIAccessToken: 'KosHeRDat2K22iDeaTe',
	defaultErrorTitle: 'Oops!',
	defaultErrorMsg: 'Something went wrong!',
	defaultAuthErrorTitle: 'Access Denied!',
	defaultAuthErrorMessage: 'Please login to continue.',
	defaultAccessibilityErrorTitle: 'Access Denied!',
	defaultAccessibilityErrorMessage: 'You don\'t have an access to this feature.',
	defaultNoResultErrorMsg: 'No result found!',
	deviceType: 'browser',
	navLevels: [0, -1, -1],
	HttpRequestTimeout: 60000, // In milliseconds
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
